<template>
    <div class="ct-biocontact">
        <hr>
        <div class="ct-biocontact_container">
            <div class="ct-bio">
                <h2 class="ct-bio_title" :data-index="`0${ index }`">
                    {{ content.bio.title }}
                </h2>
                <div
                    class="ct-bio_item"
                    v-for="( ct, idx ) in content.bio.items"
                    :key="`ct-bio_item-${ idx }`">
                    <h3 :data-index="order.charAt( idx )">{{ ct.title }}</h3>
                    <p class="ct-bio_item-text" v-html="ct.data"></p>
                </div>
            </div>
            <div class="ct-contact">
                <hr id="ct-contact_hr">
                <h2 class="ct-contact_title" :data-index="`0${ index + 1 }`">
                    {{ content.contact.title }}
                </h2>
                <h3 data-index="a">{{ content.contact.name }}</h3>
                <dl
                    v-for="( ct, idx ) in content.contact.items"
                    :key="`ct-contact_item-${ idx }`">
                    <dt>{{ ct.title }}</dt>
                    <dd v-html="ct.data"></dd>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'biocontact',

        props: {
            index: Number,
            content: Object
        },

        data() {
            return {
                'order': 'abcdefghijklmnopqrstuvwxyz'
            }
        }
    }
</script>
