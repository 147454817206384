<template>
    <div class="ct-gallery">
        <hr>
        <h1 class="ct-gallery_title" :data-index="`0${ index }`">
            {{ content.title }}
        </h1>
        <div class="ct-gallery_container">
            <div
                class="ct-gallery_item"
                v-for="( item, index ) in content.items"
                :key="`ct-gallery_item-${ index }`"
                :data-index="order.charAt( index )"
            >
                <img :src="item" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Gallery',

        props: {
            index: Number,
            content: Object
        },

        data() {
            return {
                'order': 'abcdefghijklmnopqrstuvwxyz'
            }
        }
    }
</script>
