<template>
    <div class="ct-clip">
        <hr>
        <h2 class="ct-clip_title" :data-index="`0${ index }`">
            {{ content.title }}
        </h2>
        <div class="ct-clip_container">
            <div class="ct-clip_left">
                <h3 data-index="a">{{ content.name }}</h3>
                <dl>
                    <dt>Release</dt>
                    <dd>{{ content.date }}</dd>
                </dl>
                <dl>
                    <dt>Playtime</dt>
                    <dd>{{ content.length }}</dd>
                </dl>
            </div>
            <div class="ct-clip_right">
                <video controls :poster="content.poster">
                    <source :src="content.video" type="video/mp4">
                </video>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Clip',

        props: {
            index: Number,
            content: Object
        }
    }
</script>
