import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Band from '@/views/Page.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/:slug',
        name: 'page',
        props: true,
        component: Band
    }
]

const router = createRouter( {
  history: createWebHistory( process.env.BASE_URL ),
  routes
} )

export default router
