<template>
    <div class="ct-about">
        <hr>
        <h1 class="ct-about_title" :data-index="`0${ index }`">
            {{ content.title }}
        </h1>
        <div class="ct-about_container">
            <div class="ct-about_left">
                <img :src="content.image" alt="">
            </div>
            <div class="ct-about_right">
                <p v-html="content.text"></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'About',

        props: {
            index: Number,
            content: Object
        }
    }
</script>
