<template>
    <div class="ct-disco_left">
        <a
            target="_blank"
            :href="content.link">
            <img
                alt=""
                :src="content.image">
        </a>
    </div>
    <div class="ct-disco_right">
        <h3 :data-index="order.charAt( index )">{{ content.name }}</h3>
        <dl>
            <dt>Release</dt>
            <dd>{{ content.date }}</dd>
        </dl>
        <dl>
            <dt>Songs</dt>
            <dd>{{ content.songs }}</dd>
        </dl>
        <dl>
            <dt>Playtime</dt>
            <dd>{{ content.length }}</dd>
        </dl>
    </div>
</template>

<script>
    export default {
        name: 'album',

        props: {
            index: Number,
            content: Object
        },

        data() {
            return {
                'order': 'abcdefghijklmnopqrstuvwxyz'
            }
        }
    }
</script>
