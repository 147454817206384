import { createApp } from 'vue'
import App from './App.vue'
import '@/registerServiceWorker'
import router from '@/scripts/router'
import cms from '@/scripts/site'

if ( !document.oldBrowser ) {
    // block back button during transitions
    window._popState = {
        block: false,
        detected: false
    }

    // back button detected
    window.addEventListener( 'popstate', () => {
        window._popState.detected = true
    } )

    // fetch content from database
    cms.init( () => {
        // handle back button during transitions
        router.beforeEach( ( to, from, next ) => {
            // handle 404 not found
            if ( to.name !== 'home' ) {
                let s = cms.data.sections.indexOf( to.params.slug )
                if ( s < 0 ) {
                    location.href = '/'
                    return
                }
            }

            // don't proceed if currently transitioning
            if ( window._popState.block ) {
                next( false )
                return
            }

            // otherwise continue
            next()
        } )

        // create app and mount it once router is ready
        const app = createApp( App )
        app.use( router )
        router.isReady().then( () => {
            app.mount( '#app' )
        } )
    } )
}
