import S from '@/scripts/site'
import W from '@wamms/tinylib'

export default {
    rnd( seed ) {
        let x = Math.sin( seed ) * 10000
        return x - Math.floor( x )
    },

    setupCharOffsets() {
        S.data.sections.forEach( ( s, i ) => {
            let hero = S.data.heros[s]
            let seed = hero.seed
            hero.offsets = new Array( hero.title.length )
            hero.title.forEach( ( t, j ) => {
                let off = 0
                hero.offsets[j] = new Array( t.length )
                for ( let k = 0; k < t.length; k++ ) {
                    let r = this.rnd( seed++ )
                    if ( ( j > 0 || k > 0 ) && r > 0.25 ) {
                        off += r * 10
                    }
                    hero.offsets[j][k] = off
                    W.sel( `.ht${ i }l${ j }c${ k }` )[0].style.transform = `translate3d( ${ off }vw, 0, 0 )`
                }
            } )
        } )
    },

    preloadImages( items, done ) {
        let total = 0
        let step = () => {
            if ( ++total >= items.length ) {
                // console.log( `${ items.length } images preloaded` )
                done()
            }
        }
        for ( let i = 0; i < items.length; i++ ) {
            let img = new Image()
            img.onerror = step
            img.onload = step
            img.src = items[i]
        }
    },

    getGradient( route ) {
        if ( route.name === 'home' ) {
            return ''
        }

        let s = route.params.slug
        let c = S.data.heros[s].background
        return `linear-gradient( 0deg, rgba( ${ c.r }, ${ c.g }, ${ c.b }, 1 ) 0%, rgba( ${ c.r }, ${ c.g }, ${ c.b }, 0 ) 100% )`
    },

    getBackground( route ) {
        // console.log( 'retrieving background color' )

        if ( route.name === 'home' ) {
            let s = S.data.sections[0]
            let c = S.data.heros[s].background
            return `rgb( ${ c.r }, ${ c.g }, ${ c.b } )`
        }

        let s = route.params.slug
        let c = S.data.heros[s].background
        return `rgb( ${ c.r }, ${ c.g }, ${ c.b } )`
    },

    getForeground( route, section = null ) {
        if ( section ) {
            let c = S.data.heros[section].color
            return `rgb( ${ c.r }, ${ c.g }, ${ c.b } )`
        } else if ( route.name === 'home' ) {
            let s = S.data.sections[0]
            let c = S.data.heros[s].color
            return `rgb( ${ c.r }, ${ c.g }, ${ c.b } )`
        }

        let s = route.params.slug
        let c = S.data.heros[s].color
        return `rgb( ${ c.r }, ${ c.g }, ${ c.b } )`
    },

    getHeroStyle( route, index ) {
        let opa = S.data.config.opacity
        let sat = S.data.config.saturation

        if ( route.name === 'home' ) {
            let o = index > 0 ? opa.inactive : opa.hero;
            let s = index > 0 ? sat.inactive : 1;
            return {
                o,
                s: `saturate( ${ s } )`,
                t: `translate3d( 101vw, 0, 0 )`
            }
        }

        let i = S.data.sections.indexOf( route.params.slug )

        return {
            o: i == index ? opa.hero : opa.inactive,
            s: `saturate( ${ i == index ? 1 : sat.inactive } )`,
            t: `translate3d( 101vw, 0, 0 )`
        }
    }
}