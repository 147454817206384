<template>
    <div id="hero">
        <div id="hero-slide_container">
            <router-link
                v-for="( section, index ) in site.data.sections"
                :to="`/${ section }`"
                :key="`hero-slide-${ index }`"
                v-slot="{ href, navigate }"
                custom>
                <a
                    :href="href"
                    @click="navigate"
                    :class="`hero-slide hero-slide-${ index } inactive`"
                    style="transform: translate3d( 101vw, 0, 0 );">
                    <img
                        :src="site.data.heros[section].image"
                        alt="">
                </a>
            </router-link>
        </div>
        <div id="hero-title_container">
            <div
                v-for="( section, index ) in site.data.sections"
                :class="`hero-title hero-title-${ index }`"
                :key="`hero-title-${ index }`">
                <div
                    v-for="( title, tidx ) in site.data.heros[section].title"
                    class="hero-title_line"
                    :key="`hero-title-${ index }_line-${ tidx }`">
                    <div
                        v-for="( char, cidx ) in title"
                        :class="`hero-title_line-char ht${ index }l${ tidx }c${ cidx }`"
                        :key="`hero-title-${ index }_line-${ tidx }_char-${ cidx }`">
                        <span
                            :class="char === ' ' ? 'space' : ''"
                            style="transform: translate3d( -101%, 0, 0 );">
                            {{ char }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="hero-last"
            class="inactive"
            @mouseover="transition.hoverLast( true )"
            @mouseleave="transition.hoverLast( false )"
            @click="transition.lastSection()">
        </div>
        <div
            id="hero-next"
            class="inactive"
            @mouseover="transition.hoverNext( true )"
            @mouseleave="transition.hoverNext( false )"
            @click="transition.nextSection()">
        </div>
        <div
            id="hero-gradient"
            class="hidden">
        </div>
        <div id="hero-scroll_container">
            <div
                id="hero-scroll"
                style="transform: translate3d( 0, 101%, 0 );">
                <span>Scroll</span>
                <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path
                        fill="currentColor"
                        d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                    </path>
                </svg>
            </div>
        </div>
    </div>
    <div id="hero-header">
        <div class="hero-container">
            <span
                id="hero-logo"
                class="inactive"
                style="transform: translate3d( 0, -101%, 0 );">
                {{ site.data.config.title }}
            </span>
        </div>
        <router-link
            to="/"
            v-slot="{ href, navigate }"
            custom>
            <a
                :href="href"
                @click="navigate"
                id="hero-close"
                class="inactive"
                style="transform: scale( 0 ); opacity: 0;">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 352 512">
                    <path 
                        fill="currentColor"
                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                    </path>
                </svg>
            </a>
        </router-link>
    </div>
    <div id="hero-footer" class="inactive">
        <div class="hero-container">
            <div
                id="hero-description"
                style="transform: translate3d( 0, 101%, 0 );">
                <span
                    class="hero-description_line"
                    v-for="( line, index ) in site.data.config.description"
                    :key="`hero-description_line-${ index }`">
                    {{ line }}
                </span>
            </div>
        </div>
        <div class="hero-container">
            <div
                id="hero-social"
                style="transform: translate3d( 0, 101%, 0 );">
                <span
                    class="hero-social_link"
                    v-for="( social, index ) in site.data.social"
                    :key="`hero-social_link-${ index }`">
                    <a 
                        target="_blank"
                        :href="social.link">
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512">
                            <path
                                fill="currentColor"
                                d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                            </path>
                        </svg>
                        {{ social.name }}
                    </a>
                </span>
            </div>
        </div>
    </div>
    <main id="content">
        <router-view v-slot="{ Component, route }">
            <transition
                mode="out-in"
                @enter="routerEnter"
                @leave="routerLeave"
                :css="false"
                appear>
                <component
                    :is="Component"
                    :key="route.path"
                    :slug="route.name === 'home' ? '' : route.params.slug"
                />
            </transition>
        </router-view>
    </main>
</template>

<script>
    import 'swiped-events'
    import W from '@wamms/tinylib'
    import T from '@/scripts/transition'
    import S from '@/scripts/site'

    export default {
        data() {
            return {
                refreshing: false,      // are we currently refreshing
                registration: null,     // service worker registration
                updateExists: false,    // is an update available
                lastView: null,         // type of last view
                
                transition: T,
                site: S
            }
        },

        // mounted() {
        //     console.log( 'mounted' )
        //     T.setup()
        // },

        created() {
            // console.log( 'created' )

            // reset scroll position on refresh
            W.scroll.reset()

            // window.addEventListener( 'scroll', () => {
            //     window.scrollTo( 0, Math.max( 0, window.scrollY ) )
            // } )

            // listen to the service worker update event
            document.addEventListener(
                'swUpdated', this.showRefreshUI, {
                    once: true
                }
            )

            // wait for the new service worker to take over
            if ( navigator.serviceWorker ) {
                navigator.serviceWorker.addEventListener(
                    'controllerchange', () => {
                        if ( this.refreshing ) {
                            return
                        }

                        // refresh the current page
                        this.refreshing = true
                        location.reload()
                    }
                )
            }
        },

        methods: {
            showRefreshUI( event ) {
                // store the service worker registration
                this.registration = event.detail
                // activate the update ui
                this.updateExists = true
            },

            refreshApp() {
                this.updateExists = false
                // early out if no service worker registration is stored
                if ( !this.registration || !this.registration.waiting ) {
                    return
                }
                // notify the new service worker to take over
                this.registration.waiting.postMessage( {
                    type: 'SKIP_WAITING'
                } )
            },

            dismissRefresh() {
                this.updateExists = false
            },

            routerLeave( el, done ) {
                // the current route is beeing left
                // console.log( `leaving <${ el.dataset.type }> to <${ this.$route.name }>` )

                // block back button
                window._popState.block = true
                // store current view
                this.lastView = el.dataset.type
                
                // transition
                setTimeout( () => {
                    if ( this.$route.name === 'home' ) {
                        T.leaveSection( done )
                    } else {
                        T.leaveHome( this.$route, done )
                    }
                }, 0 )
            },

            routerEnter( el, done ) {
                // a new route is beeing entered
                // console.log( `entering <${ this.$route.name }> from <${ this.lastView || 'nowhere' }>` )

                // update page title
                if ( this.$route.name === 'home' ) {
                    S.title()
                } else {
                    S.title( S.data.titles[ this.$route.params.slug ] )
                }

                // intro
                if ( !this.lastView ) {
                    T.intro( el, this.$route, () => {
                        // unblock and reset back button
                        window._popState.detected = false
                        window._popState.block = false
                        // finally continue
                        done()
                    } )
                }
                // transition
                else {
                    if ( this.$route.name === 'home' ) {
                        // transition to home
                        T.enterHome( done )
                    } else {
                        // transition to section
                        T.enterSection( el, this.$route, done )
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    @import '~destyle.css/destyle.css';
    @import '@/styles/config.scss';
    @import '@/styles/tools.scss';
    @import '@/styles/global.scss';
    @import '@/styles/issues.scss';
    @import '@/styles/hero.scss';
    @import '@/styles/section.scss';
    @import '@/styles/mobile.scss';
</style>
