export default {
    // cms base
    cms: '/cms/db20240825.json',
    // site data
    data: null,

    // fetch site data
    init( callback ) {
        fetch( `${ this.cms }` )
            .then( response => response.json() )
            .then( data => {
                // store data
                this.data = data
                // finally continue
                callback()
            } )
            .catch( error => console.error( 'Error fetching site\n', error ) )
    },

    title( name = null ) {
        document.title = `${ name ? name + this.data.config.separator : '' }${ this.data.config.title }`;
    }
}