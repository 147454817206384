<template>
    <div id="content-container" data-type="page">
        <div id="content-page">
            <component
                v-for="( section, index ) in site.data.content[slug]"
                :key="`content-section-${ index }`"
                :is="section.type"
                :content="section.data"
                :index="index + 1"
            />
            <hr>
        </div>
        <div id="content-footer">
            <div id="footer-description">
                <!-- <span class="hero-description_line">
                    &copy; Claus Riedl {{ Math.max( 2021, new Date().getFullYear() ) }}
                </span> -->
                <span
                    class="hero-description_line"
                    v-for="( line, index ) in site.data.config.description"
                    :key="`hero-description_line-${ index }`">
                    {{ line }}
                </span>
            </div>
            <div id="footer-scroll">
                <!-- <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path
                        fill="currentColor"
                        d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                    </path>
                </svg>
                <span>To Top</span> -->
                <span class="hero-description_line">
                    &copy; Claus Riedl {{ Math.max( 2021, new Date().getFullYear() ) }}
                </span>
            </div>
            <div id="footer-social">
                <span
                    class="hero-social_link"
                    v-for="( social, index ) in site.data.social"
                    :key="`hero-social_link-${ index }`">
                    <a 
                        target="_blank"
                        :href="social.link">
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512">
                            <path
                                fill="currentColor"
                                d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                            </path>
                        </svg>
                        {{ social.name }}
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import S from '@/scripts/site'
    import About from '@/components/About'
    import Clip from '@/components/Clip'
    import Gallery from '@/components/Gallery'
    import Discography from '@/components/Discography'
    import BioContact from '@/components/BioContact'

    export default {
        name: 'Page',

        components: {
            'about': About,
            'clip': Clip,
            'gallery': Gallery,
            'discography': Discography,
            'biocontact': BioContact
        },

        props: {
            slug: String
        },

        data() {
            return {
                site: S
            }
        },

        // mounted() {
        //     this.$emit( 'mounted' )
        // }
    }
</script>
