import W from '@wamms/tinylib'
import S from '@/scripts/site'
import T from '@/scripts/tools'

const easing = 3
const duration = 1000

export default {

    section: 0,
    animLast: null,
    animNext: null,
    animScroll: false,
    hideScroll: false,
    hasMouse: false,
    swipeLeft: null,
    swipeRight: null,

    addSwipeControl() {
        if ( this.swipeLeft == null ) {
            this.swipeLeft = document.addEventListener( 'swiped-left', () => {
                if ( !window._popState.block ) {
                    this.nextSection()
                }
            } )
        }
        if ( this.swipeRight == null ) {
            this.swipeRight = document.addEventListener( 'swiped-right', () => {
                if ( !window._popState.block ) {
                    this.lastSection()
                }
            } )
        }
    },

    removeSwipeControl() {
        if ( this.swipeLeft != null ) {
            document.removeEventListener( this.swipeLeft )
            this.swipeLeft = null
        }
        if ( this.swipeRight != null ) {
            document.removeEventListener( this.swipeRight )
            this.swipeRight = null
        }
    },

    intro( el, route, done ) {
        // check if mouse is available
        this.hasMouse = window.matchMedia( '( pointer: fine ) and ( hover: hover )' ).matches

        // handle scrolling
        if ( this.animScroll ) {
            W.listen( document, true, 'scroll', () => {
                if ( this.hideScroll ) {
                    this.hideScroll = false
                    const a = new W.anim( {
                        s: '#hero-scroll',
                        d: duration / 2,
                        e: `i${ easing }`,
                        p: {
                            y: [0, 101]
                        }
                    } )
                    a.play()
                }
            } )
        }

        // calculate char offsets
        T.setupCharOffsets()

        let b = T.getBackground( route )
        W.sel( '#hero, #content' ).forEach( ( e ) => {
            e.style.backgroundColor = b
        } )

        let f = T.getForeground( route )
        W.sel( '#hero-logo, #hero-close, #hero-description, #hero-scroll, #hero-social, #content' ).forEach( ( e ) => {
            e.style.color = f
        } )

        S.data.sections.forEach( ( s, i ) => {
            W.sel( `.hero-title-${ i }` )[0].style.color = T.getForeground( route, s )

            let e = W.sel( `.hero-slide-${ i }` )[0]
            let h = T.getHeroStyle( route, i )
            e.style.opacity = h.o
            e.style.filter = h.s
            e.style.transform = h.t
        } )

        // preload hero images
        const items = new Array()
        W.sel( '#hero-slide_container img' ).forEach( ( e ) => {
            // console.log( `preloading ${ e.src }` )
            items.push( e.src )
        } )
        T.preloadImages( items, () => {
            if ( route.name === 'home' ) {
                this.introMain( done )
            } else {
                let g = W.sel( '#hero-gradient' )[0]
                g.style.background = T.getGradient( route )
                g.classList.remove( 'hidden' )
        
                this.section = S.data.sections.indexOf( route.params.slug )
                this.introSection( el, route, done )    
            }    
        } )
    },

    introMain( done ) {
        // console.log( 'intro' )

        const tl = new W.tl()
        tl.add( {
            s: '#hero-logo',
            d: duration / 2,
            e: `o${ easing }`,
            p: {
                y: [-101, 0]
            }
        } )
        tl.add( {
            s: '#hero-description, #hero-social',
            d: duration / 2,
            e: `o${ easing }`,
            p: {
                y: [101, 0]
            }
        } )
        tl.add( {
            s: '.hero-slide-0',
            d: duration,
            e: `o${ easing }`,
            p: {
                x: [101, 20, 'vw']
            }
        } )
        tl.add( {
            s: '.hero-slide-1',
            d: duration,
            e: `o${ easing }`,
            p: {
                x: [171, 90, 'vw']
            }
        } )
        tl.add( {
            s: '.hero-title-0 span',
            d: duration / 2,
            w: duration / 2,
            e: `o${ easing }`,
            p: {
                x: [-101, 0]
            },
            c: () => {
                W.sel( '#hero-next, .hero-slide-0, #hero-footer' ).forEach( ( e ) => {
                    e.classList.remove( 'inactive' )
                } )
                this.addSwipeControl()
                done()
            }
        } )
        tl.play()
    },

    introSection( el, route, done ) {
        const tl = new W.tl()
        tl.add( {
            s: '#hero-logo',
            d: duration / 2,
            e: `o${ easing }`,
            p: {
                y: [-101, 0]
            }
        } )
        tl.add( {
            s: `.hero-slide-${ this.section }`,
            d: duration,
            e: `o${ easing }`,
            p: {
                x: [101, 20, 'vw']
            }
        } )
        tl.add( {
            s: `.hero-title-${ this.section } span`,
            d: duration / 2,
            w: duration / 2,
            e: `o${ easing }`,
            p: {
                x: [-101, 0]
            },
            c: () => {
                this.leaveHome( route, () => {
                    this.enterSection( el, route, () => {
                        done()
                    } )
                }, false )
            }
        } )
        tl.play()
    },

    lastSection() {
        // console.log( 'last section' )

        const l = S.data.sections.length
        if ( this.section <= 0 ) {
            return
        }

        if ( this.animLast ) {
            this.animLast.pause()
            this.animLast = null
        }

        this.removeSwipeControl()
        window._popState.block = true
        W.sel( '#hero-last, #hero-next, .hero-slide' ).forEach( ( e ) => {
            e.classList.add( 'inactive' )
        } )

        const el = W.sel( `.hero-slide-${ this.section - 1 }` )
        const cf = S.data.config
        const s1 = S.data.sections[this.section]
        const s2 = S.data.sections[this.section - 1]
        const b1 = S.data.heros[s1].background
        const b2 = S.data.heros[s2].background
        const c1 = S.data.heros[s1].color
        const c2 = S.data.heros[s2].color

        const tl = new W.tl()
        if ( this.section < l - 1 ) {
            tl.add( {
                s: `.hero-slide-${ this.section + 1 }`,
                d: duration,
                e: `io${ easing }`,
                p: {
                    x: [90, 160, 'vw']
                }
            } )
        }
        tl.add( {
            s: '#hero',
            d: duration,
            e: `io${ easing }`,
            u: ( d ) => {
                let r = Math.floor( W.lerp( b1.r, b2.r, d.v ) )
                let g = Math.floor( W.lerp( b1.g, b2.g, d.v ) )
                let b = Math.floor( W.lerp( b1.b, b2.b, d.v ) )
                for ( let e of d.s ) {
                    e.style.backgroundColor = `rgb( ${ r }, ${ g }, ${ b } )`
                }
            }
        } )
        tl.add( {
            s: '#hero-logo, #hero-description, #hero-social',
            d: duration,
            e: `io${ easing }`,
            u: ( d ) => {
                let r = Math.floor( W.lerp( c1.r, c2.r, d.v ) )
                let g = Math.floor( W.lerp( c1.g, c2.g, d.v ) )
                let b = Math.floor( W.lerp( c1.b, c2.b, d.v ) )
                for ( let e of d.s ) {
                    e.style.color = `rgb( ${ r }, ${ g }, ${ b } )`
                }
            }
        } )
        tl.add( {
            s: `.hero-slide-${ this.section }`,
            d: duration,
            e: `io${ easing }`,
            p: {
                x: [20, 90, 'vw'],
                o: [cf.opacity.hero, cf.opacity.inactive]
            },
            u: ( d ) => {
                let s = W.round( W.lerp( 1, cf.saturation.inactive, d.v ), d.r )
                for ( let e of d.s ) {
                    e.style.filter = `saturate( ${ s } )`
                }
            }
        } )
        tl.add( {
            s: `.hero-title-${ this.section } span`,
            d: duration / 2,
            e: `i${ easing }`,
            p: {
                x: [0, -101]
            }
        } )
        if ( this.section > 1 ) {
            tl.add( {
                s: `.hero-slide-${ this.section - 2 }`,
                d: duration,
                e: `io${ easing }`,
                p: {
                    x: [-120, -50, 'vw'],
                }
            } )    
        }
        tl.add( {
            s: el,
            d: duration,
            e: `io${ easing }`,
            p: {
                x: [-50, 20, 'vw'],
                o: [el[0].style.opacity, cf.opacity.hero]
            },
            u: ( d ) => {
                let s = W.round( W.lerp( el[0].style.filter.match( /[+-]?\d+(\.\d+)?/g ), 1, d.v ), d.r )
                for ( let e of d.s ) {
                    e.style.filter = `saturate( ${ s } )`
                }
            }
        } )
        tl.add( {
            s: `.hero-title-${ this.section - 1 } span`,
            d: duration / 2,
            w: duration / 2,
            e: `o${ easing }`,
            p: {
                x: [101, 0]
            },
            c: () => {
                window._popState.block = false
                W.sel( `#hero-next${ this.section > 0 ? ', #hero-last' : '' }, .hero-slide-${ this.section }` ).forEach( ( e ) => {
                    e.classList.remove( 'inactive' )
                } )
                this.addSwipeControl()
            }
        } )

        this.section--;
        tl.play()
    },

    nextSection() {
        // console.log( 'next section' )

        const l = S.data.sections.length
        if ( this.section >= l - 1 ) {
            return
        }

        if ( this.animNext ) {
            this.animNext.pause()
            this.animNext = null
        }

        this.removeSwipeControl()
        window._popState.block = true
        W.sel( '#hero-last, #hero-next, .hero-slide' ).forEach( ( e ) => {
            e.classList.add( 'inactive' )
        } )

        const el = W.sel( `.hero-slide-${ this.section + 1 }` )
        const cf = S.data.config
        const s1 = S.data.sections[this.section]
        const s2 = S.data.sections[this.section + 1]
        const b1 = S.data.heros[s1].background
        const b2 = S.data.heros[s2].background
        const c1 = S.data.heros[s1].color
        const c2 = S.data.heros[s2].color

        const tl = new W.tl()
        if ( this.section > 0 ) {
            tl.add( {
                s: `.hero-slide-${ this.section - 1 }`,
                d: duration,
                e: `io${ easing }`,
                p: {
                    x: [-50, -120, 'vw']
                }
            } )
        }
        tl.add( {
            s: '#hero',
            d: duration,
            e: `io${ easing }`,
            u: ( d ) => {
                let r = Math.floor( W.lerp( b1.r, b2.r, d.v ) )
                let g = Math.floor( W.lerp( b1.g, b2.g, d.v ) )
                let b = Math.floor( W.lerp( b1.b, b2.b, d.v ) )
                for ( let e of d.s ) {
                    e.style.backgroundColor = `rgb( ${ r }, ${ g }, ${ b } )`
                }
            }
        } )
        tl.add( {
            s: '#hero-logo, #hero-description, #hero-social',
            d: duration,
            e: `io${ easing }`,
            u: ( d ) => {
                let r = Math.floor( W.lerp( c1.r, c2.r, d.v ) )
                let g = Math.floor( W.lerp( c1.g, c2.g, d.v ) )
                let b = Math.floor( W.lerp( c1.b, c2.b, d.v ) )
                for ( let e of d.s ) {
                    e.style.color = `rgb( ${ r }, ${ g }, ${ b } )`
                }
            }
        } )
        tl.add( {
            s: `.hero-slide-${ this.section }`,
            d: duration,
            e: `io${ easing }`,
            p: {
                x: [20, -50, 'vw'],
                o: [cf.opacity.hero, cf.opacity.inactive]
            },
            u: ( d ) => {
                let s = W.round( W.lerp( 1, cf.saturation.inactive, d.v ), d.r )
                for ( let e of d.s ) {
                    e.style.filter = `saturate( ${ s } )`
                }
            }
        } )
        tl.add( {
            s: `.hero-title-${ this.section } span`,
            d: duration / 2,
            e: `i${ easing }`,
            p: {
                x: [0, 101]
            }
        } )
        if ( this.section < l - 2 ) {
            tl.add( {
                s: `.hero-slide-${ this.section + 2 }`,
                d: duration,
                e: `io${ easing }`,
                p: {
                    x: [160, 90, 'vw'],
                }
            } )    
        }
        tl.add( {
            s: el,
            d: duration,
            e: `io${ easing }`,
            p: {
                x: [90, 20, 'vw'],
                o: [el[0].style.opacity, cf.opacity.hero]
            },
            u: ( d ) => {
                let s = W.round( W.lerp( el[0].style.filter.match( /[+-]?\d+(\.\d+)?/g ), 1, d.v ), d.r )
                for ( let e of d.s ) {
                    e.style.filter = `saturate( ${ s } )`
                }
            }
        } )
        tl.add( {
            s: `.hero-title-${ this.section + 1 } span`,
            d: duration / 2,
            w: duration / 2,
            e: `o${ easing }`,
            p: {
                x: [-101, 0]
            },
            c: () => {
                window._popState.block = false
                W.sel( `#hero-last${ this.section < l - 1 ? ', #hero-next' : '' }, .hero-slide-${ this.section }` ).forEach( ( e ) => {
                    e.classList.remove( 'inactive' )
                } )
                this.addSwipeControl()
            }
        } )

        this.section++;
        tl.play()
    },

    hoverLast( active ) {
        // console.log( `hover last ${ active ? 'started' : 'stopped' }` )

        if ( !this.hasMouse ) {
            return
        }

        if ( this.animLast ) {
            this.animLast.pause()
            this.animLast = null
        }

        if ( this.section <= 0 ) {
            return
        }

        const w = W.sel( `.hero-slide-${ this.section - 1 }, .hero-slide-${ this.section - 2 }` )
        const s = S.data.config.saturation
        const o = S.data.config.opacity
        const a = new W.anim( {
            s: w,
            d: 250,
            e: `io${ easing }`,
            p: {
                o: [w[0].style.opacity, active ? o.active : o.inactive]
            },
            u: ( d ) => {
                let v = W.round( W.lerp( w[0].style.filter.match( /[+-]?\d+(\.\d+)?/g ), active ? s.active : s.inactive, d.v ), d.r )
                for ( let e of d.s ) {
                    e.style.filter = `saturate( ${ v } )`
                }
            }
        } )

        this.animLast = a
        a.play()
    },

    hoverNext( active ) {
        // console.log( `hover next ${ active ? 'started' : 'stopped' }` )

        if ( !this.hasMouse ) {
            return
        }

        if ( this.animNext ) {
            this.animNext.pause()
            this.animNext = null
        }

        if ( this.section >= S.data.sections.length - 1 ) {
            return
        }

        const w = W.sel( `.hero-slide-${ this.section + 1 }, .hero-slide-${ this.section + 2 }` )
        const s = S.data.config.saturation
        const o = S.data.config.opacity
        const a = new W.anim( {
            s: w,
            d: 250,
            e: `io${ easing }`,
            p: {
                o: [w[0].style.opacity, active ? o.active : o.inactive]
            },
            u: ( d ) => {
                let v = W.round( W.lerp( w[0].style.filter.match( /[+-]?\d+(\.\d+)?/g ), active ? s.active : s.inactive, d.v ), d.r )
                for ( let e of d.s ) {
                    e.style.filter = `saturate( ${ v } )`
                }
            }
        } )

        this.animNext = a
        a.play()
    },

    leaveSection( done ) {
        // block events
        window._popState.block = true
        W.sel( '#hero-close' )[0].classList.add( 'inactive' )

        // scroll to top
        W.scroll.top( () => {
            done()
        } )

        const tl = new W.tl()
        // const es = W.sel( '#hero-scroll' )
        // hide close
        tl.add( {
            s: '#hero-close',
            d: duration / 2,
            e: `io${ easing }`,
            p: {
                s: [1, 0],
                o: [1, 0]
            }
        } )
        // hide scroll
        if ( this.hideScroll ) {
            this.hideScroll = false
            tl.add( {
                s: '#hero-scroll', //es,
                d: duration / 2,
                e: `i${ easing }`,
                p: {
                    y: [0, 101] // es[0].style.transform.match( /[+-]?\d+(\.\d+)?/g )[2]
                }
            } )
        }
        tl.play()
    },

    leaveHome( route, done, fromHome = true ) {
        // update and show gradient
        let g = W.sel( '#hero-gradient' )[0]
        g.style.background = T.getGradient( route )
        g.classList.remove( 'hidden' )

        // block events
        this.removeSwipeControl()
        window._popState.block = true
        W.sel( '#hero-last, #hero-next, .hero-slide, #hero-footer' ).forEach( ( e ) => {
            e.classList.add( 'inactive' )
        } )

        // update content background
        let b = T.getBackground( route )
        W.sel( '#content' )[0].style.backgroundColor = b

        // update close, scroll and content colors
        let f = T.getForeground( route )
        W.sel( '#hero-close, #hero-scroll, #content' ).forEach( ( e ) => {
            e.style.color = f
        } )

        const cf = S.data.config
        const tl = new W.tl()
        if ( fromHome ) {
            // hide description and social
            tl.add( {
                s: '#hero-description, #hero-social',
                d: duration / 2,
                e: `i${ easing }`,
                p: {
                    y: [0, 101]
                }
            } )
            // scroll out left section
            if ( this.section > 0 ) {
                tl.add( {
                    s: `.hero-slide-${ this.section - 1 }`,
                    d: duration,
                    e: `i${ easing }`,
                    p: {
                        x: [-50, -120, 'vw'],
                    }
                } )
            }
            // scroll out right section
            if ( this.section < S.data.sections.length - 1 ) {
                tl.add( {
                    s: `.hero-slide-${ this.section + 1 }`,
                    d: duration,
                    e: `i${ easing }`,
                    p: {
                        x: [90, 150, 'vw'],
                    }
                } )
            }
        }
        // scroll title chars
        let h = S.data.heros[route.params.slug]
        h.title.forEach( ( t, i ) => {
            for ( let j = 0; j < t.length; j++ ) {
                tl.add( {
                    s: `.ht${ this.section }l${ i }c${ j }`,
                    d: duration,
                    e: `io${ easing }`,
                    p: {
                        x: [h.offsets[i][j], 0, 'vw']
                    }
                } )
            }
        } )
        // zoom in hero
        tl.add( {
            s: `.hero-slide-${ this.section }`,
            d: duration,
            e: `io${ easing }`,
            p: {
                s: [1, 2],
                x: [20, 20, 'vw'],
                o: [cf.opacity.hero, cf.opacity.section]
            },
            c: () => {
                // finally continue
                done()
            }
        } )

        // play animation
        tl.play()
    },

    enterSection( el, route, done ) {
        const tl = new W.tl()
        // show close
        tl.add( {
            s: '#hero-close',
            d: duration / 2,
            w: duration / 2,
            e: `io${ easing }`,
            p: {
                s: [0, 1],
                o: [0, 1]
            }
        } )
        // show scroll
        tl.add( {
            s: '#hero-scroll',
            d: duration / 2,
            e: `o${ easing }`,
            p: {
                y: [101, 0]
            },
            c: () => {
                // unblock events
                window._popState.block = false
                W.sel( '#hero-close' )[0].classList.remove( 'inactive' )
                W.body.classList.remove( 'fixed' )
                this.hideScroll = true

                // finally continue
                done()
            }
        } )

        // preload content images
        const items = new Array()
        el.querySelectorAll( 'img' ).forEach( ( e ) => {
            // console.log( `preloading ${ e.src }` )
            items.push( e.src )
        } )
        el.querySelectorAll( 'video' ).forEach( ( e ) => {
            // console.log( `preloading ${ e.poster }` )
            items.push( e.poster )
        } )
        T.preloadImages( items, () => {
            // play animation
            tl.play()
        } )
    },

    enterHome( done ) {
        // fix body
        W.body.classList.add( 'fixed' )

        const cf = S.data.config
        const tl = new W.tl()
        // scroll title chars
        let h = S.data.heros[S.data.sections[this.section]]
        h.title.forEach( ( t, i ) => {
            for ( let j = 0; j < t.length; j++ ) {
                tl.add( {
                    s: `.ht${ this.section }l${ i }c${ j }`,
                    d: duration,
                    e: `io${ easing }`,
                    p: {
                        x: [0, h.offsets[i][j], 'vw']
                    }
                } )
            }
        } )
        // zoom out hero
        tl.add( {
            s: `.hero-slide-${ this.section }`,
            d: duration,
            e: `io${ easing }`,
            p: {
                s: [2, 1],
                x: [20, 20, 'vw'],
                o: [cf.opacity.section, cf.opacity.hero]
            }
        } )
        // scroll in left section
        if ( this.section > 0 ) {
            tl.add( {
                s: `.hero-slide-${ this.section - 1 }`,
                d: duration,
                e: `o${ easing }`,
                p: {
                    x: [-120, -50, 'vw'],
                }
            } )
        }
        // scroll in right section
        if ( this.section < S.data.sections.length - 1 ) {
            tl.add( {
                s: `.hero-slide-${ this.section + 1 }`,
                d: duration,
                e: `o${ easing }`,
                p: {
                    x: [150, 90, 'vw'],
                }
            } )
        }
        // show description and social
        tl.add( {
            s: '#hero-description, #hero-social',
            d: duration / 2,
            w: duration / 2,
            e: `o${ easing }`,
            p: {
                y: [101, 0]
            },
            c: () => {
                // hide gradient
                W.sel( '#hero-gradient' )[0].classList.add( 'hidden' )

                // unblock events
                window._popState.block = false
                W.sel( '#hero-last, #hero-next, .hero-slide, #hero-footer' ).forEach( ( e ) => {
                    e.classList.remove( 'inactive' )
                } )

                // finally continue
                this.addSwipeControl()
                done()
            }
        } )

        // play animation
        tl.play()
    }
}