<template>
    <div class="ct-disco">
        <hr>
        <h2 class="ct-disco_title" :data-index="`0${ index }`">
            {{ content.title }}
        </h2>
        <div class="ct-disco_container">
            <album
                v-for="( item, index ) in content.items"
                :key="`ct-disco_item-${ index }`"
                :index="index"
                :content="item"
            />
        </div>
    </div>
</template>

<script>
    import Album from '@/components/Album'

    export default {
        name: 'Discography',

        components: {
            'album': Album
        },

        props: {
            index: Number,
            content: Object
        }
    }
</script>
